.column-with-copy .ant-table-header-column {
  display: block !important;
}
.column-with-copy .ant-table-column-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.column-with-copy .ant-table-column-title svg {
  cursor: pointer;
  width: 17px;
  height: 17px;
}