.custom_tooltip {
  border-radius: 5px;
  box-shadow: 2px 2px 6px -4px #999;
  cursor: default;
  font-size: 14px;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  white-space: nowrap;
  z-index: 12;
  transition: 0.15s ease all;
  font-size: 12px;
}

.custom_tooltip .header {
  padding: 6px;
  margin-bottom: 4px;
  background-color: #ECEFF1;
  border-bottom: 1px solid #ddd;
}

.custom_tooltip .content {
  padding: 0 10px;
  padding-bottom: 4px;
}

.custom_tooltip .task_content {
  padding: 4px 8px;
}