.header-container {
    margin: 0px -16px;
    padding: 15px;
    background: #fff;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.header-container h1 {
    font-size: 20px;
}

.header-container .search-buttons {
    display: flex;
    gap: 10px;
}

.header-container .search-buttons .custom-dates {
    display: flex;
    align-items: center;
    gap: 4px;
}

.fullscreen-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    padding: 0;
    transition: transform 0.3s;
}

.fullscreen-button:active {
    transform: scale(0.7);
}

.fullscreen-button i {
    font-size: 22px;
}

.ant-card-head-title {
    text-align: center;
    font-size: 18px;
}

@media (max-width: 1024px) {
    .header-container .search-buttons {
        flex-direction: column;
        align-items: center;
    }
}

@media (max-width: 768px) {
    .header-container .search-buttons .custom-dates {
        flex-direction: column;
        gap: 6px;
    }
}