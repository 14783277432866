.custom-skeleton .ant-skeleton-content .ant-skeleton-paragraph {
  border: 1px solid #e6e6e6;
}

.custom-skeleton .ant-skeleton-content .ant-skeleton-paragraph li:first-child {
  height: 50px;
}

.custom-skeleton .ant-skeleton-content .ant-skeleton-paragraph li:not(.custom-skeleton .ant-skeleton-content .ant-skeleton-paragraph li:first-child) {
  height: 30px;
  margin-top: 24px;
}