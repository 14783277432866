.card-title-container {
  display: flex;
  justify-content: space-between;
}

.title {
  font-size: 18px;
}

.month-container {
  display: flex;
  align-items: center;
}

.month-text {
  font-weight: bold;
  font-size: 14px;
  text-transform: capitalize;
  line-height: inherit;
}

.month-container button {
  margin-left: 6px;
}