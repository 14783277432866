.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
  z-index: 1000;
  display: flex;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging td:nth-child(3) {
  flex: 1;
}

.row-dragging .drag-visible {
  visibility: visible;
}